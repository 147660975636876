import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components'

// layouts
import HomepageLayout from './Layouts/HomepageLayout'
import MainLayout from './Layouts/MainLayout'

// pages
import Homepage from './Pages/Homepage'
import DiscoverAuthors from './Pages/DiscoverAuthors'
import DiscoverContent from './Pages/DiscoverContent'
import ErrorPage from './Pages/ErrorPage'

// scss & theming
import './SCSS/theme.scss'
import { getInitialTheme, setSelectedTheme } from './Utils/theming'

// styled
const AppContainer = styled.div`
	height: 100%;
	width: 100%;
`

function App(props) {
	useEffect(() => {
		// Check localStorage for theme preference then set theme
		setSelectedTheme(getInitialTheme())
	}, [])

	return (
		<AppContainer>
			<Routes>
				<Route
					exact
					path='/'
					element={
						<HomepageLayout>
							<Homepage />
						</HomepageLayout>
					}
				/>
				<Route
					path='/discover/authors'
					element={
						<MainLayout>
							<DiscoverAuthors />
						</MainLayout>
					}
				/>
				<Route
					path='/discover/content'
					element={
						<MainLayout>
							<DiscoverContent />
						</MainLayout>
					}
				/>
				<Route
					path='*'
					element={
						<MainLayout>
							<ErrorPage />
						</MainLayout>
					}
				/>
			</Routes>
		</AppContainer>
	)
}

export default App
