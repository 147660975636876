import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

// components
import AuthorAvatar from './AuthorAvatar'
import TweetMedia from './TweetMedia'
import Tag from '../Tag'
import {
	TweetBlockContainer,
	TweetBlockInnerContainer,
	TweetHeaderContainer,
	TweetContentContainer,
	TextContainer,
	TweetFooterContainer,
	RedirectContainer,
	TagsDisplay,
	FlexGrowFiller,
	PopperWrapper,
	PopperContentsContainer,
	Button,
} from './components'

// icons
import ArrowRightIcon from '../../Assets/Icons/ArrowRightIcon'
import MoreHorizontalIcon from '../../Assets/Icons/MoreHorizontalIcon'

// utils
import { formatTweet } from '../../Utils/text'

const CollapsedThread = (props) => {
	const {
		documentID,
		threadPreviewText,
		threadLength,
		authorName,
		authorHandle,
		avatar_url,
		twitter_url,
		threadTwitterURL,
		threadPreviewImages,
		tags,
	} = props
	const [hasMedia, setHasMedia] = useState(false)
	const formattedPreviewText = formatTweet(threadPreviewText)

	const [searchParams, setSearchParams] = useSearchParams()

	useEffect(() => {
		if (Array.isArray(threadPreviewImages) && threadPreviewImages.length > 0) {
			setHasMedia(true)
		}
	}, [])

	// for tags
	const handleTagClick = (e, value) => {
		e.stopPropagation()
		// dispatch(setTweetsFilters([{ filterType: 'Topics', option: value }]))
		setSearchParams({ topics: `${value}` })
	}

	const configAvatar = {
		displayName: authorName,
		handle: authorHandle,
		avatarURL: avatar_url,
		twitterURL: twitter_url,
		threadTwitterURL: threadTwitterURL,
	}

	return (
		<TweetBlockContainer>
			<TweetBlockInnerContainer>
				<TweetHeaderContainer>
					<AuthorAvatar {...configAvatar} />
				</TweetHeaderContainer>
				<TweetContentContainer>
					<TextContainer>{formattedPreviewText}</TextContainer>
					{hasMedia && <TweetMedia photos={threadPreviewImages} />}
				</TweetContentContainer>
				<TweetFooterContainer>
					<Link
						to={`/read/${documentID}`}
						style={{ color: 'rgb(109, 121, 136)' }}
					>
						{threadLength > 1 ? (
							<RedirectContainer>
								<TextContainer>Read {threadLength} tweets</TextContainer>
								<ArrowRightIcon />
							</RedirectContainer>
						) : (
							<RedirectContainer>
								<TextContainer>Open in reader</TextContainer>
								<ArrowRightIcon />
							</RedirectContainer>
						)}
					</Link>
				</TweetFooterContainer>
				<TagsDisplay>
					{tags &&
						tags.map((tag, index) => (
							<Tag key={index} onClick={(e) => handleTagClick(e, tag)}>
								{tag}
							</Tag>
						))}
				</TagsDisplay>
			</TweetBlockInnerContainer>
		</TweetBlockContainer>
	)
}

export default CollapsedThread
