export const formatTweet = (tweet) => {
	const mapObj = {
		'&lt;': '<',
		'&gt;': '>',
		'&amp;': '&',
		'&cent;': '¢',
		'&pound;': '£',
		'&yen;': '¥',
		'&euro;': '€',
		'&copy;': '©',
		'&reg;': '®',
		'   ': '\n\n',
		'  ': '\n',
	}

	const formattedTweet = tweet.replaceAll(
		/&lt;|&gt;|&amp;|&pound;|&yen;|&euro;|&copy;|&reg;|\s\s+|\s\s/g,
		function (matched) {
			return mapObj[matched]
		}
	)

	return formattedTweet
}
