import React from 'react'
import styled from 'styled-components'

const TweetBodyMediaMask = styled.div`
	margin-top: 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-radius: 10px;
	pointer-events: none;
`

const TweetBodyMediaImg = styled.img`
	width: 100%;
	height: auto;
	filter: var(--selected-img-filter);
`

const TweetMedia = (props) => {
	const { photos } = props
	return (
		<TweetBodyMediaMask>
			{photos.map((url) => (
				<TweetBodyMediaImg key={url} src={url} />
			))}
		</TweetBodyMediaMask>
	)
}

export default TweetMedia
