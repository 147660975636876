import { createSlice } from '@reduxjs/toolkit'

export const threadsSlice = createSlice({
	name: 'threads',
	initialState: {
		threads: [],
		fetchInitialThreadsStatus: 'idle',
		fetchInitialThreadsError: null,
	},
	reducers: {
		fetchInitialThreadsStart: (state, action) => {
			state.fetchInitialThreadsStatus = 'loading'
		},
		fetchInitialThreadsSuccess: (state, action) => {
			state.fetchInitialThreadsStatus = 'succeeded'
			state.threads = action.payload
		},
		fetchInitialThreadsFail: (state, action) => {
			state.fetchInitialThreadsStatus = 'failed'
			state.fetchInitialThreadsError = action.payload
		},
	},
})

export const {
	fetchInitialThreadsStart,
	fetchInitialThreadsSuccess,
	fetchInitialThreadsFail,
} = threadsSlice.actions

export default threadsSlice.reducer

export const selectAllThreads = (state) => state.threads.threads
export const selectFetchInitialThreadsStatus = (state) =>
	state.threads.fetchInitialThreadsStatus
export const selectFetchInitialThreadsError = (state) =>
	state.threads.fetchInitialThreadsError
