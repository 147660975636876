import { db } from '../../Firebase/'
import {
	collection,
	query,
	where,
	orderBy,
	limit,
	getDocs,
} from 'firebase/firestore'

var groupBy = function (data, key) {
	return data.reduce(function (storage, item) {
		var group = item[key]

		storage[group] = storage[group] || []

		storage[group].push(item['option'])

		return storage
	}, {})
}

export const handleFetchThreads = async ({
	authorHandle,
	filterOptions,
	startAfterDoc,
	persistTweets = [],
}) => {
	const pageSize = 20
	const threadsRef = collection(db, 'approvedThreads')
	const conditions = [orderBy('total_likes', 'desc'), limit(pageSize)]

	if (authorHandle) {
		conditions.push(where('handle', '==', authorHandle))

		if (filterOptions && filterOptions.length > 0) {
			const filterOptionsGrouped = groupBy(filterOptions, 'filterType')

			// 1. only "Topics" filter on
			if (filterOptionsGrouped['Topics'] && !filterOptionsGrouped['Types']) {
				conditions.push(
					where('tags', 'array-contains-any', filterOptionsGrouped['Topics'])
				)
			}

			// 2. only "Types" filter on
			if (!filterOptionsGrouped['Topics'] && filterOptionsGrouped['Types']) {
				conditions.push(
					where('types', 'array-contains-any', filterOptionsGrouped['Types'])
				)
			}

			// 3. "Topics" + "Types"
			if (filterOptionsGrouped['Topics'] && filterOptionsGrouped['Types']) {
				conditions.push(
					where('tags', 'array-contains-any', filterOptionsGrouped['Topics']),
					where('types', '==', filterOptionsGrouped['Types'][0])
				)
			}
		}
	} else {
		if (filterOptions && filterOptions.length > 0) {
			const filterOptionsGrouped = groupBy(filterOptions, 'filterType')

			// 1. only "Authors" filter on
			if (
				filterOptionsGrouped['Authors'] &&
				!filterOptionsGrouped['Topics'] &&
				!filterOptionsGrouped['Types']
			) {
				conditions.push(where('author', 'in', filterOptionsGrouped['Authors']))
			}

			// 2. only "Topics" filter on
			if (
				!filterOptionsGrouped['Authors'] &&
				filterOptionsGrouped['Topics'] &&
				!filterOptionsGrouped['Types']
			) {
				conditions.push(
					where('tags', 'array-contains-any', filterOptionsGrouped['Topics'])
				)
			}

			// 3. only "Types" filter on
			if (
				!filterOptionsGrouped['Authors'] &&
				!filterOptionsGrouped['Topics'] &&
				filterOptionsGrouped['Types']
			) {
				conditions.push(
					where('types', 'array-contains-any', filterOptionsGrouped['Types'])
				)
			}

			// 4. "Authors" + "Topics"
			if (
				filterOptionsGrouped['Authors'] &&
				filterOptionsGrouped['Topics'] &&
				!filterOptionsGrouped['Types']
			) {
				conditions.push(
					where('author', '==', filterOptionsGrouped['Authors'][0]),
					where('tags', 'array-contains-any', filterOptionsGrouped['Topics'])
				)
			}

			// 5. "Authors" + "Types"
			if (
				filterOptionsGrouped['Authors'] &&
				!filterOptionsGrouped['Topics'] &&
				filterOptionsGrouped['Types']
			) {
				conditions.push(
					where('author', '==', filterOptionsGrouped['Authors'][0]),
					where('types', 'array-contains-any', filterOptionsGrouped['Types'])
				)
			}

			// 6. "Topics" + "Types"
			if (
				!filterOptionsGrouped['Authors'] &&
				filterOptionsGrouped['Topics'] &&
				filterOptionsGrouped['Types']
			) {
				conditions.push(
					where('tags', 'array-contains-any', filterOptionsGrouped['Topics']),
					where('types', '==', filterOptionsGrouped['Types'][0])
				)
			}

			// 7. All true
			if (
				filterOptionsGrouped['Authors'] &&
				filterOptionsGrouped['Topics'] &&
				filterOptionsGrouped['Types']
			) {
				conditions.push(
					where('author', '==', filterOptionsGrouped['Authors'][0]),
					where('tags', 'array-contains-any', filterOptionsGrouped['Topics']),
					where('types', '==', filterOptionsGrouped['Types'][0])
				)
			}
		}
	}

	try {
		const q = query(threadsRef, ...conditions)
		const snapshot = await getDocs(q)
		const totalCount = snapshot.size

		const data = [
			...persistTweets,
			...snapshot.docs.map((doc) => {
				return {
					...doc.data(),
					documentID: doc.id,
					lastModified: JSON.stringify(doc.get('lastModified')),
					firstAdded: JSON.stringify(doc.get('firstAdded')),
				}
			}),
		]

		return {
			data,
			queryDoc: snapshot.docs[totalCount - 1],
			isLastPage: totalCount < pageSize,
		}
	} catch (error) {
		console.log(error)
		return error
	}
}
