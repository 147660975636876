import styled from 'styled-components'

const NavbarContainer = styled.header`
	width: 100%;
	height: 48px;
	border-bottom-color: var(--selected-border-color);
	border-bottom-width: 1px;
	border-bottom-style: solid;
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Navbar = styled.nav`
	width: 100%;
	height: 100%;
	padding: 0 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

const MobileMenuContainer = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: 632px) {
		display: none;
	}
`

const LogoContainer = styled.div`
	color: var(--selected-text-main);
	font-weight: 600;
	font-size: 16px;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	flex: 1 1 0;
`

const TabsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0 24px;
	flex: 1 1 160px;

	@media (max-width: 632px) {
		display: none;
	}
`

const TabLabel = styled.div`
	margin-right: 24px;
	color: ${(props) =>
		props.active ? 'var(--selected-text-main)' : 'rgb(200, 204, 210)'};
	font-size: 20px;
	font-weight: 500;
	cursor: pointer;
	user-select: none;
	font-family: 'Inter', 'Helvetica Neue', sans-serif;
`

const SignInBtnsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	flex: 1 1 0;
`

const SignInBtn = styled.button`
	margin: 0 10px;
	color: var(--selected-text-main);
	background-color: transparent;
	border: 0;
	font-size: 16px;
	font-weight: 600;
	white-space: nowrap;
	cursor: pointer;
`

const SignUpBtn = styled.button`
	padding: 6px 18px;
	border: 0;
	border-radius: 20px;
	background-color: var(--primary-background);
	color: var(--selected-surface);
	font-size: 16px;
	font-weight: 600;
	white-space: nowrap;
	cursor: pointer;
`

export {
	NavbarContainer,
	Navbar,
	MobileMenuContainer,
	LogoContainer,
	TabsContainer,
	TabLabel,
	SignInBtnsContainer,
	SignInBtn,
	SignUpBtn,
}
