import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// components
import Header from '../../Components/Header'
import MobileMenu from '../../Components/MobileMenu'

const MainLayoutContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
`

const Main = styled.main`
	width: 100%;
	height: calc(100vh - 48px);
`

const MainLayout = (props) => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

	// disable background scrolling when modal open
	useEffect(() => {
		if (mobileMenuOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = ''
		}
	}, [mobileMenuOpen])

	const toggleMobileMenu = () => {
		setMobileMenuOpen(!mobileMenuOpen)
	}

	const configHeader = {
		mobileMenuOpen: mobileMenuOpen,
		onOpenMobileMenu: toggleMobileMenu,
	}

	return (
		<MainLayoutContainer>
			<Header {...configHeader} />
			{mobileMenuOpen && <MobileMenu />}
			<Main>{props.children}</Main>
		</MainLayoutContainer>
	)
}

export default MainLayout
