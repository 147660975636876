import styled from 'styled-components'

const AuthorContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 0;
	min-height: 0;
	max-height: 100%;
	user-select: none;
`
const AvatarContainer = styled.div`
	margin: 0 12px 0 0;
	flex-grow: 0;
`

const AvatarMask = styled.div`
	border-radius: 50%;
	width: 36px;
	height: 36px;
	overflow: hidden;
	background: var(--selected-base);
`

const Avatar = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const AuthorNameContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: stretch;
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 0;
	min-height: 0;
	max-height: 100%;
	margin: 0 12px 0 0;
`

const AuthorName = styled.h4`
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	word-break: break-word;
	white-space: nowrap;
	letter-spacing: -0.00622354em;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
`

const AuthorVerified = styled.div`
	margin-left: 4px;
`

const AuthorHandle = styled.p`
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	word-break: break-word;
	letter-spacing: 0.000490774em;
	color: rgb(109, 121, 136);
	font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
`

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 16px;
	width: 16px;
`

export {
	AuthorContainer,
	AvatarContainer,
	AvatarMask,
	Avatar,
	AuthorNameContainer,
	AuthorName,
	AuthorVerified,
	AuthorHandle,
	IconContainer,
}
