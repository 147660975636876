import React from 'react'

import Masonry from 'react-masonry-css'
import './styles.scss'

// components
import CollapsedThread from '../../CollapsedThread'
import {
	GalleryViewContainer,
	GalleryContainer,
	GalleryCenteredContainer,
	GridItem,
	GridContainer,
	EndOfPageIndicatorContainer,
	TextContainer,
} from './components'

const TweetsGallery = (props) => {
	const { threads, isLastPage, onToggleModalEvt } = props

	const breakpointColumnsObj = {
		default: 4,
		1240: 3,
		936: 2,
		632: 1,
	}

	if (!Array.isArray(threads)) return null

	if (threads.length < 1) {
		return (
			<GalleryViewContainer>
				<GalleryContainer>
					<TextContainer
						style={{ color: 'rgb(109, 121, 136)', padding: '48px' }}
					>
						Sorry, we don't have what you're looking for yet. Let us know -here-
						so we can focus on this type of content more!
					</TextContainer>
				</GalleryContainer>
			</GalleryViewContainer>
		)
	}

	return (
		<GalleryViewContainer>
			<GalleryContainer>
				<GalleryCenteredContainer>
					<GridContainer>
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className='grid'
							columnClassName='grid_column'
						>
							{threads.map((threadObj, index) => {
								const {
									documentID,
									thread,
									author,
									handle,
									authorAvatarURL,
									authorTwitterURL,
									tags,
								} = threadObj
								const threadPreviewText = thread[0]['tweet']
								const threadPreviewImages = thread[0]['photos']
								const threadLength = thread.length
								const threadTwitterURL = thread[0]['link']

								const configCollapsedThread = {
									documentID,
									threadPreviewText,
									threadLength,
									authorName: author,
									authorHandle: handle,
									avatar_url: authorAvatarURL,
									twitter_url: authorTwitterURL,
									threadTwitterURL,
									threadPreviewImages,
									tags,
								}

								if (threads.length === index + 1) {
									return (
										<GridItem
											key={`${index}-${documentID}`}
											onClick={() => onToggleModalEvt({ ...threadObj })}
										>
											<CollapsedThread {...configCollapsedThread} />
										</GridItem>
									)
								} else {
									return (
										<GridItem
											key={`${index}-${documentID}`}
											onClick={() => onToggleModalEvt({ ...threadObj })}
										>
											<CollapsedThread {...configCollapsedThread} />
										</GridItem>
									)
								}
							})}
						</Masonry>
					</GridContainer>
				</GalleryCenteredContainer>
				{isLastPage && (
					<EndOfPageIndicatorContainer>
						<TextContainer style={{ color: 'rgb(109, 121, 136)' }}>
							Looks like you've reached the end
						</TextContainer>
					</EndOfPageIndicatorContainer>
				)}
			</GalleryContainer>
		</GalleryViewContainer>
	)
}

export default TweetsGallery
