import styled from 'styled-components'

const ModalOverlay = styled.div`
	position: fixed;
	inset: 0px;
	background: rgba(0, 0, 0, 0.85);
	z-index: 100;

	@media (max-width: 632px) {
		display: none;
	}
`

const ModalContainer = styled.div`
	position: relative;
	z-index: 110;
	width: calc(100vw - 96px);
	height: calc(100vh - 96px);
	margin: 48px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: var(--selected-base);
	box-shadow: rgb(15 15 15 / 5%) 0px 0px 0px 1px,
		rgb(15 15 15 / 10%) 0px 5px 10px, rgb(15 15 15 / 20%) 0px 15px 40px;
	border-radius: 4px;

	@media (max-width: 835px) {
		flex-direction: column;
		overflow: hidden auto;
		justify-content: flex-start;
		width: calc(100vw - 48px);
		height: calc(100vh - 48px);
		margin: 24px;
	}

	@media (max-width: 632px) {
		flex-direction: column;
		overflow: hidden auto;
		justify-content: flex-start;
		width: 100vw;
		height: 100vh;
		margin: 0;
	}
`

const ContentWrapper = styled.div`
	height: 100%;
	width: ${(props) => (props.fullWidth ? '100% ' : 'calc(100% - 300px)')};
	position: relative;
	z-index: 111;

	@media (max-width: 835px) {
		height: auto;
		width: 100%;
	}
`

const ContentContainer = styled.div`
	height: 100%;
	width: 100%;
	background-color: var(--selected-surface);
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	overflow: hidden auto;
	padding: 80px;

	@media (max-width: 835px) {
		height: auto;
		overflow: initial;
		padding: 24px;
	}
`

const InspectorContainer = styled.div`
	height: 100%;
	width: 300px;
	background-color: var(--selected-base);
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 24px;
	overflow-y: auto;

	@media (max-width: 835px) {
		overflow-y: initial;
		width: 100%;
		border-bottom-right-radius: 4px;
	}
`

const ContentPreview = styled.div`
	width: 100%;
	max-width: 600px;
	margin-top: 48px;
	margin-left: auto;
	margin-right: auto;
`

const FieldContainer = styled.div`
	width: 100%;
	height: auto;
`

const FieldTitleContainer = styled.div`
	width: 100%;
	height: 27px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`

const FieldTitleText = styled.div`
	color: rgb(108, 112, 119);
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	font-family: BasierSquare, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
		Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`

const FieldCellContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 8px;
`

const FieldValueContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: auto;
	line-height: 120%;
	word-break: break-all;
`

const FieldRow = styled.div`
	width: 100%;
	padding: 4px 0px;
`

const FieldDivider = styled.div`
	margin: 16px auto;
	width: 100%;
	min-height: 1px;
	height: 1px;
	background: #e1e1e1;
`

const ActionButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: none;
	background: var(--selected-overlay-light);
	color: var(--selected-text-secondary);
	cursor: pointer;

	&:hover {
		background: var(--selected-text-secondary);
		color: var(--selected-overlay-light);
	}
`

const MaximizeBtnContainer = styled.div`
	position: absolute;
	top: 20px;
	left: 20px;
`

const ToggleInspectorContianer = styled.div`
	position: absolute;
	top: 20px;
	right: 28px;

	@media (max-width: 835px) {
		top: 20px;
		right: 28px;
	}
`

const BookmarkBtnContainer = styled.div`
	position: absolute;
	top: 80px;
	right: 28px;

	@media (max-width: 835px) {
		top: 20px;
		right: 28px;
	}
`

const CloseModalBtnContainer = styled.div`
	position: absolute;
	top: 36px;
	right: 36px;
	z-index: 120;

	@media (max-width: 835px) {
		top: 12px;
		right: 12px;
	}
`

const CloseModalBtn = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: none;
	background: var(--selected-overlay-dark);
	color: #fff;
	cursor: pointer;
`

const EditBtn = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.05);
	color: #2d7ff9;
	padding: 4px 12px;
	border-radius: 3px;
	cursor: pointer;
	user-select: none;
	white-space: nowrap;
	font-size: 12px;
	font-weight: 500;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;

	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
`

const StyledLink = styled.a`
	&:hover {
		text-decoration: underline;
	}
`

export {
	ModalOverlay,
	ModalContainer,
	ContentWrapper,
	ContentContainer,
	InspectorContainer,
	ContentPreview,
	FieldContainer,
	FieldTitleContainer,
	FieldTitleText,
	FieldCellContainer,
	FieldValueContainer,
	FieldRow,
	FieldDivider,
	ActionButton,
	MaximizeBtnContainer,
	ToggleInspectorContianer,
	BookmarkBtnContainer,
	CloseModalBtnContainer,
	CloseModalBtn,
	EditBtn,
	StyledLink,
}
