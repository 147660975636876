import styled from 'styled-components'

const TabsSection = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 12px 24px;
	height: auto;
	width: 100%;
`

const Tab = styled.div`
	margin: 0px 12px;
	color: ${(props) =>
		props.active ? 'var(--selected-text-main)' : 'rgb(200, 204, 210)'};
	font-size: 32px;
	font-weight: 700;
	cursor: pointer;
	user-select: none;
	font-family: 'Inter', 'Helvetica Neue', sans-serif;
`

export { TabsSection, Tab }
