export const getSelectedCssProps = () => {
	// Get all 'selected' custom CSS properties from the ':root'.
	// These are the variables that are actually used (as oppose to vars to store the alternatives from different themes)

	// get all stylesheets in an array
	const styleSheetList = []
	for (let i = 0; i < document.styleSheets.length; i++) {
		styleSheetList.push(document.styleSheets[i])
	}

	// helper function to check if the stylesheet queried is from same domain
	const isSameDomain = (styleSheet) => {
		if (!styleSheet.href) {
			return true
		}

		return styleSheet.href.indexOf(window.location.origin) === 0
	}

	const ownStyleSheetsList = styleSheetList.filter(isSameDomain)

	// get all cssRule
	const cssRuleList = []
	for (let i = 0; i < ownStyleSheetsList.length; i++) {
		cssRuleList.push(ownStyleSheetsList[i].cssRules)
	}

	// get all cssStyleRule
	const cssStyleRuleList = []
	for (let i = 0; i < cssRuleList.length; i++) {
		for (let j = 0; j < cssRuleList[i].length; j++) {
			cssStyleRuleList.push(cssRuleList[i][j])
		}
	}

	// filter out style rules defined to :root
	const rootStyleRule = cssStyleRuleList.filter(
		(name) => name.selectorText === ':root'
	)

	// get all css rules declared
	const cssStyleDeclaration = []
	for (let i = 0; i < rootStyleRule.length; i++) {
		cssStyleDeclaration.push(rootStyleRule[i].style)
	}

	// get all the css props
	const cssProps = []
	for (let i = 0; i < cssStyleDeclaration.length; i++) {
		for (let j = 0; j < cssStyleDeclaration[i].length; j++) {
			cssProps.push(cssStyleDeclaration[i][j])
		}
	}

	// filter out those that start with --selected
	const selectedCssProps = cssProps.filter((name) =>
		name.startsWith('--selected')
	)

	return selectedCssProps
}

export const setSelectedTheme = (themeName) => {
	const selectedCssProps = getSelectedCssProps()

	// Set the selected values to values of a different theme
	selectedCssProps.forEach((prop) => {
		// set each selected variable with its analogous variable from the new theme
		document.documentElement.style.setProperty(
			prop,
			`var(--${themeName}${prop.substring(10)})`
		)
	})
}

export const getInitialTheme = () => {
	const persistedThemePreference = window.localStorage.getItem('theme')
	const hasPersistedPreference = typeof persistedThemePreference === 'string'

	// Check localStorage for theme preference
	if (hasPersistedPreference) {
		return persistedThemePreference
	}

	// If no localStorage, check media query
	const mql = window.matchMedia('(prefers-color-scheme: dark)')
	const hasMediaQueryPreference = typeof mql.matches === 'boolean'
	if (hasMediaQueryPreference) {
		return mql.matches ? 'dark' : 'light'
	}

	// default to 'dark' if no preference or browser/OS doesn't support
	return 'dark'
}

export const setSelectedFont = (fontName) => {
	const selectedCssProps = getSelectedCssProps()
	const fontFamilyCssProp = selectedCssProps.filter((name) =>
		name.startsWith('--selected-font-family')
	)

	fontFamilyCssProp.forEach((prop) => {
		document.documentElement.style.setProperty(prop, `var(--${fontName}-fonts)`)
	})
}

export const setSelectedFontSize = (value) => {
	const selectedCssProps = getSelectedCssProps()
	const fontFamilyCssProp = selectedCssProps.filter((name) =>
		name.startsWith('--selected-font-size')
	)

	fontFamilyCssProp.forEach((prop) => {
		document.documentElement.style.setProperty(prop, `${value}px`)
	})
}
