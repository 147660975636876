import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	fetchInitialThreadsStart,
	selectAllThreads,
	selectFetchInitialThreadsStatus,
	selectFetchInitialThreadsError,
} from '../../Redux/Threads/threads.slice'

// data
import { tweetsFilterTypes, tweetsGroupedFilterOptions } from './filterItems'

// custom hooks
import { useWindowSize } from '../../CustomHooks'

// utils
import {
	addTweetsFilterOption,
	removeFilterOption,
	removeLastFilterOption,
	parseURLValidTweetsFilters,
	matchQueryParamsWithMetaFilters,
} from '../../Utils/search'

// components
import { Page } from '../components'
import DiscoverTabs from '../../Components/DiscoverTabs'
import Search from '../../Components/Search'
import TweetsResults from '../../Components/TweetsResults'
import Peek from '../../Components/Peek'

const DiscoverContent = () => {
	const [selectedFilterType, setSelectedFilterType] = useState(null)
	const [selectedFilterOption, setSelectedFilterOption] = useState(null)
	const [selectedFilterOptions, setSelectedFilterOptions] = useState([])
	const dispatch = useDispatch()
	const threads = useSelector(selectAllThreads)
	const initialThreadsStatus = useSelector(selectFetchInitialThreadsStatus)
	const initialThreadsError = useSelector(selectFetchInitialThreadsError)

	const [openModal, setOpenModal] = useState(false)
	const [threadToPeek, setThreadToPeek] = useState(null)
	const { width } = useWindowSize()

	// for results
	useEffect(() => {
		dispatch(fetchInitialThreadsStart({ filterOptions: selectedFilterOptions }))
	}, [selectedFilterOptions])

	// for search
	const handleFilterTypeChange = (value) => {
		setSelectedFilterType(value)
	}

	const handleFilterOptionChange = (value) => {
		setSelectedFilterOption(value)
	}

	const handleAddFilterOption = (option) => {
		const updatedOptions = addTweetsFilterOption(selectedFilterOptions, option)
		// dispatch(setAuthorsFilters(updatedOptions))
		setSelectedFilterOptions(updatedOptions)
	}

	const handleRemoveFilterOption = (option) => {
		const updatedOptions = removeFilterOption(selectedFilterOptions, option)
		// dispatch(setAuthorsFilters(updatedOptions))
		setSelectedFilterOptions(updatedOptions)
	}

	const handleRemoveLastFilterOption = () => {
		const updatedOptions = removeLastFilterOption(selectedFilterOptions)
		// dispatch(setAuthorsFilters(updatedOptions))
		setSelectedFilterOptions(updatedOptions)
	}

	// disable background scrolling when modal open
	useEffect(() => {
		if (openModal) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = ''
		}
	}, [openModal])

	// for peek
	const toggleModal = (contentToPeek) => {
		if (width > 632) {
			setOpenModal(!openModal)
			setThreadToPeek(contentToPeek)
		} else {
			return
		}
	}

	// Configs for children
	const configSearch = {
		topLevelFilters: tweetsFilterTypes,
		groupedOptions: tweetsGroupedFilterOptions,
		placeholderText: 'Search tweets by...',
		selectedFilterType: selectedFilterType,
		onFilterTypeChange: handleFilterTypeChange,
		selectedFilterOption: selectedFilterOption,
		onFilterOptionChange: handleFilterOptionChange,
		selectedFilterOptions: selectedFilterOptions,
		onAddFilterOption: handleAddFilterOption,
		onRemoveFilterOption: handleRemoveFilterOption,
		onRemoveLastFilterOption: handleRemoveLastFilterOption,
	}

	const configTweetsResults = {
		threads: threads,
		initialThreadsStatus: initialThreadsStatus,
		initialThreadsError: initialThreadsError,
		filterOptions: selectedFilterOptions,
		onToggleModalEvt: toggleModal,
	}

	const configPeek = {
		onToggleModalEvt: toggleModal,
		threadToPeek: threadToPeek,
	}

	return (
		<Page>
			<DiscoverTabs activePage='content' />
			<Search {...configSearch} />
			<TweetsResults {...configTweetsResults} />

			{openModal && <Peek {...configPeek} />}
		</Page>
	)
}

export default DiscoverContent
