import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

// styled
const ModalOverlay = styled.div`
	position: absolute;
	z-index: 150;
	top: 48px;
	left: 0px;
`

const ModalContainer = styled.div`
	width: 100vw;
	height: calc(100vh - 48px);
	background: var(--selected-base);
	color: var(--selected-text-main);
`

const MenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 24px;
	user-select: none;
`

const MenuOption = styled.div`
	margin: 20px 24px;
`

const MenuText = styled.span`
	font-size: 40px;
	font-weight: 700;
	font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
`

const MobileMenu = () => {
	const pages = [
		{
			pathname: '/',
			label: 'Home',
		},
		{
			pathname: '/discover/authors',
			label: 'Discover',
		},
		{
			pathname: 'library',
			label: 'Library',
		},
	]

	return (
		<ModalOverlay>
			<ModalContainer>
				<MenuContainer>
					{pages.map((page) => (
						<MenuOption>
							<Link to={page.pathname}>
								<MenuText>{page.label}</MenuText>
							</Link>
						</MenuOption>
					))}
				</MenuContainer>
			</ModalContainer>
		</ModalOverlay>
	)
}

export default MobileMenu
