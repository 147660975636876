import React from 'react'
import styled from 'styled-components'

// styled
const AuthorContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 16px;
`

const Avatar = styled.img`
	width: 48px;
	height: 48px;
	border-radius: 50%;
`

const NamesContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 4px;
`

const DisplayName = styled.div`
	font-size: 15px;
	font-weight: 700;
	line-height: 1.3125;
	opacity: 0.8;
`

const Handle = styled.div`
	font-size: 14px;
	font-weight: 400;
	color: var(--selected-text-secondary);
`

const FlexFiller = styled.div`
	flex-grow: 1;
`

const TweetHead = (props) => {
	const { author, handle, avatarURL } = props

	return (
		<AuthorContainer>
			<Avatar src={avatarURL} alt={author} />
			<NamesContainer>
				<DisplayName>{author}</DisplayName>
				<Handle>@{handle}</Handle>
			</NamesContainer>
			<FlexFiller />
		</AuthorContainer>
	)
}

export default TweetHead
