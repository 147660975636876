import React, { useState } from 'react'
import styled from 'styled-components'

// components
import Header from '../../Components/Header'
import MobileMenu from '../../Components/MobileMenu'

// styled
const HomepageLayoutContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
`

const Main = styled.main`
	margin: 0 auto;
	min-height: calc(100vh - 48px);
	overflow: ${(props) => (props.modelOpen ? 'hidden' : 'auto')};
`

const HomepageLayout = (props) => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

	const toggleMobileMenu = () => {
		setMobileMenuOpen(!mobileMenuOpen)
	}

	const configHeader = {
		mobileMenuOpen: mobileMenuOpen,
		onOpenMobileMenu: toggleMobileMenu,
	}

	return (
		<HomepageLayoutContainer>
			<Header {...configHeader} />
			{mobileMenuOpen && <MobileMenu />}
			<Main modelOpen={mobileMenuOpen}>{props.children}</Main>
		</HomepageLayoutContainer>
	)
}

export default HomepageLayout
