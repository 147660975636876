import styled from 'styled-components'

const TweetBlockContainer = styled.div`
	width: 280px;
	box-shadow: rgb(0 0 0 / 8%) 0px -1px 1px, rgb(0 0 0 / 8%) 1px 0px 1px,
		rgb(0 0 0 / 8%) -1px 0px 1px, rgb(0 0 0 / 8%) 0px 1px 1px;
	border-radius: 4px;
	background: var(--selected-surface);
	isolation: isolate;

	@media (min-width: 632px) {
		&:hover {
			background: var(--selected-overlay-light);
		}
	}
`

const TweetBlockInnerContainer = styled.div`
	width: calc(100%-32px);
	height: calc(100%-32px);
	padding: 16px;
`

const TweetHeaderContainer = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: 16px;
`

const TweetContentContainer = styled.div`
	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	flex-grow: 1;
	flex-shrink: 0;
	min-width: 0;
	min-height: 0;
	max-height: 100%;
	user-select: none;

	&:last-child {
		margin-bottom: 0px;
	}
`

const TextContainer = styled.p`
	font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	word-break: break-word;
`

const TweetFooterContainer = styled.div`
	padding-top: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`

const RedirectContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const TagsDisplay = styled.div`
	width: 248px;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: flex-start;
	overflow: hidden auto;
	padding-top: 16px;
`
const FlexGrowFiller = styled.div`
	flex-grow: 1;
`

const PopperWrapper = styled.div`
	display: ${(props) => (props.isEditingTags ? 'hidden' : 'block')};
`

const PopperContentsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 4px;
`
const Button = styled.button`
	background-color: var(--selected-overlay-light);
	color: var(--selected-text-main);
	width: 100%;
	text-align: left;
	padding: 6px 8px;
	border-width: 1px;
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: var(--selected-surface);
	}
`

export {
	TweetBlockContainer,
	TweetBlockInnerContainer,
	TweetHeaderContainer,
	TweetContentContainer,
	TextContainer,
	TweetFooterContainer,
	RedirectContainer,
	TagsDisplay,
	FlexGrowFiller,
	PopperWrapper,
	PopperContentsContainer,
	Button,
}
