import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// icons
import XIcon16 from '../../Assets/Icons/XIcon16'
import MaximizeIcon from '../../Assets/Icons/MaximizeIcon'
import ChevronsLeftIcon from '../../Assets/Icons/ChevronsLeftIcon'
import ChevronsRightIcon from '../../Assets/Icons/ChevronsRightIcon'
import BookmarkIcon16 from '../../Assets/Icons/BookmarkIcon16'
import TweetInPeek from './TweetInPeek'

// components
import Tag from '../Tag'
import {
	CloseModalBtn,
	CloseModalBtnContainer,
	ContentContainer,
	ContentWrapper,
	FieldContainer,
	FieldDivider,
	InspectorContainer,
	ModalContainer,
	ModalOverlay,
	ActionButton,
	MaximizeBtnContainer,
	ToggleInspectorContianer,
	BookmarkBtnContainer,
	ContentPreview,
	FieldTitleContainer,
	FieldCellContainer,
	FieldValueContainer,
	FieldTitleText,
	StyledLink,
} from './components'

const Peek = (props) => {
	const { onToggleModalEvt, threadToPeek } = props
	const {
		author,
		handle,
		authorAvatarURL,
		authorTwitterURL,
		tags,
		thread,
		documentID,
	} = threadToPeek
	const threadTwitterURL = thread[0]['link']
	const publishedDate = thread[0]['date']

	const [openInspector, setOpenInspector] = useState(true)

	const toggleInspector = () => {
		setOpenInspector(!openInspector)
	}

	const configAuthor = {
		author: author,
		handle: handle,
		avatarURL: authorAvatarURL,
		tweetURL: authorTwitterURL,
	}

	return (
		<ModalOverlay>
			<ModalContainer>
				<ContentWrapper fullWidth={!openInspector}>
					<Link to={`/read/${documentID}`}>
						<MaximizeBtnContainer>
							<ActionButton>
								<MaximizeIcon />
							</ActionButton>
						</MaximizeBtnContainer>
					</Link>
					<ContentContainer>
						<ContentPreview>
							{thread.map((tweetObj, index) => {
								const { id } = tweetObj

								return (
									<TweetInPeek
										key={id}
										isLast={index === thread.length - 1}
										authorObj={configAuthor}
										tweetObj={tweetObj}
									/>
								)
							})}
						</ContentPreview>
					</ContentContainer>

					<ToggleInspectorContianer onClick={toggleInspector}>
						<ActionButton>
							{openInspector ? <ChevronsRightIcon /> : <ChevronsLeftIcon />}
						</ActionButton>
					</ToggleInspectorContianer>

					<BookmarkBtnContainer>
						<ActionButton>
							<BookmarkIcon16 />
						</ActionButton>
					</BookmarkBtnContainer>
				</ContentWrapper>
				{openInspector && (
					<InspectorContainer>
						<FieldContainer>
							<FieldTitleContainer>Author</FieldTitleContainer>
							<FieldCellContainer>
								<FieldValueContainer>{author}</FieldValueContainer>
							</FieldCellContainer>
						</FieldContainer>
						<FieldDivider />
						<FieldContainer>
							<FieldTitleContainer>Tags</FieldTitleContainer>
							<FieldCellContainer>
								<FieldValueContainer>
									{Array.isArray(tags) &&
										tags.length > 0 &&
										tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}
								</FieldValueContainer>
							</FieldCellContainer>
						</FieldContainer>
						<FieldDivider />
						<FieldContainer>
							<FieldTitleContainer>
								<FieldTitleText>Source</FieldTitleText>
							</FieldTitleContainer>
							<FieldCellContainer>
								<StyledLink
									href={threadTwitterURL}
									target='_blank'
									rel='noopener noreferrer'
								>
									<FieldValueContainer>Twitter</FieldValueContainer>
								</StyledLink>
							</FieldCellContainer>
						</FieldContainer>
						<FieldDivider />
						<FieldContainer>
							<FieldTitleContainer>
								<FieldTitleText>Published Date</FieldTitleText>
							</FieldTitleContainer>
							<FieldCellContainer>
								<FieldValueContainer>{publishedDate}</FieldValueContainer>
							</FieldCellContainer>
						</FieldContainer>
					</InspectorContainer>
				)}
			</ModalContainer>
			<CloseModalBtnContainer>
				<CloseModalBtn onClick={onToggleModalEvt}>
					<XIcon16 />
				</CloseModalBtn>
			</CloseModalBtnContainer>
		</ModalOverlay>
	)
}

export default Peek
