const flattenGroupedOptions = (sections) => {
	let res = []

	sections.map((section) => {
		var filterType = section.filterType

		section.options.map((option) => {
			let newOpt = {
				value: option.value,
				filterType: filterType,
				icon: filterType,
			}
			res.push(newOpt)
		})
	})

	return res
}

export { flattenGroupedOptions }
