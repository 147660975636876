const tweetsGroupedFilterOptions = [
	{
		filterType: 'Authors',
		options: [
			{ value: 'Pomp' },
			{ value: 'Sam Altman' },
			{ value: 'Andrew Chen' },
			{ value: 'Naval' },
			{ value: 'Shaan Puri' },
			{ value: 'GREG ISENBERG' },
			{ value: 'Austin Rief' },
			{ value: 'Li Jin' },
			{ value: 'Alexis Ohanian' },
			{ value: 'Matthew Ball' },
			{ value: 'Eugene Wei' },
			{ value: 'Jack Butcher' },
			{ value: 'Justin Kan' },
			{ value: 'Visualize Value' },
			{ value: 'Shreyas Doshi' },
			{ value: 'Sahil Bloom' },
			{ value: 'Paul Graham' },
			{ value: 'Sahil' },
			{ value: 'Jeff Morris Jr.' },
			{ value: 'Julian Shapiro' },
			{ value: 'Conor White-Sullivan' },
			{ value: 'Garry Tan' },
			{ value: 'Tim Ferriss' },
			{ value: 'Alex Taussig' },
			{ value: 'Ryan Hoover' },
			{ value: 'Bill Gurley' },
		],
	},
	{
		filterType: 'Topics',
		options: [
			{ value: '#Creator Economy' },
			{ value: '#Break Into VC' },
			{ value: '#Passion Economy' },
			{ value: '#1000 True Fans' },
			{ value: '#Marketplace' },
			{ value: '#Startups' },
			{ value: '#Hiring' },
			{ value: '#Company Culture' },
			{ value: '#Venture Investing' },
			{ value: '#Goal Setting' },
			{ value: '#Life Advice' },
			{ value: '#How to be Successful' },
			{ value: '#Learning' },
			{ value: '#Life Wisdom' },
			{ value: '#Policy Making' },
			{ value: '#Management' },
			{ value: '#Career Advice' },
			{ value: '#Entrepreneurship' },
			{ value: '#Silicon Valley' },
			{ value: '#CrowdWisdom' },
			{ value: '#Politics' },
			{ value: '#Religion' },
			{ value: '#Investor Update' },
			{ value: '#How to Get Rich' },
			{ value: '#Product Management' },
			{ value: '#Compounding Effect' },
			{ value: '#Leadership' },
			{ value: '#SocialCause' },
			{ value: '#Email Writing' },
		],
	},
	{
		filterType: 'Types',
		options: [
			{ value: 'Analysis' },
			{ value: 'Case Study' },
			{ value: 'Commentary' },
			{ value: 'Discussion' },
			{ value: 'Essay' },
			{ value: 'Framework' },
			{ value: 'Mental Model' },
			{ value: 'Observation' },
			{ value: 'Prediction' },
			{ value: 'Recommendation' },
			{ value: 'Stories' },
			{ value: 'Trends' },
		],
	},
]

const tweetsFilterTypes = [
	{ value: 'Authors', filterType: 'TopLevel', icon: 'Authors' },
	{ value: 'Topics', filterType: 'TopLevel', icon: 'Topics' },
	{ value: 'Types', filterType: 'TopLevel', icon: 'Types' },
]

export { tweetsFilterTypes, tweetsGroupedFilterOptions }
