import styled from 'styled-components'

// icons
import ChevronRightIcon from '../../Assets/Icons/ChevronRightIcon'

const SearchSectionContainer = styled.section`
	position: sticky;
	top: 0px;
	z-index: 10;
`

const SearchContainer = styled.div`
	height: auto;
	padding: 16px 0px;
	background: var(--selected-base);
	position: relative;
`

const SearchbarContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 0px auto;
	width: 1192px;
	height: auto;
	@media (max-width: 1240px) {
		max-width: 888px;
	}
	@media (max-width: 936px) {
		max-width: 584px;
	}
	@media (max-width: 632px) {
		max-width: 280px;
	}
`

const SearchbarInnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-radius: 16px;
	height: 36px;
	width: 100%;
	border: 2px solid rgb(200, 204, 210);
	overflow: auto hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
`

const SearchIconContainer = styled.div`
	display: block;
	height: 16px;
	flex: 0 0 16px;
	margin: auto 12px;
`

// Autocomplete
const ComboBox = styled.div`
	width: 100%;
	min-width: 200px;
	height: auto;
`

const Input = styled.input`
	width: 100%;
	height: 32px;
	font-size: 15px;
	padding-right: 40px;
	background: var(--selected-base);
	color: rgb(109, 121, 136);
	outline: none;
	border: none;
`

const MenuContainer = styled.div`
	position: absolute;
	top: 48px;
	left: 0px;
	right: 0px;
	margin: 16px auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: var(--selected-base);
	border-radius: 8px;
	box-shadow: rgb(0 0 0 / 16%) 0px 2px 6px;
	width: 1192px;
	@media (max-width: 1240px) {
		max-width: 888px;
	}
	@media (max-width: 936px) {
		max-width: 584px;
	}
	@media (max-width: 632px) {
		max-width: 278px;
	}
`

const MenuInnerContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`

const MenuListContainer = styled.div`
	padding: 4px 8px 8px;
	width: 100%;
	max-height: 300px;
	overflow: auto;
`

const MenuHeaderText = styled.div`
	margin: 8px auto 8px 8px;
	width: auto;
	height: auto;
	color: var(--selected-text-secondary);
	font-family: 'Inter', 'Helvetica Neue', sans-serif;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 1.3px;
`

const MenuItemContainer = styled.div`
	width: 100%;
	height: 36px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 0px auto;
	padding: 8px;
	border-radius: 8px;
	cursor: pointer;
	user-select: none;
`

const MenuFooter = styled.div`
	width: 100%;
	padding: 8px 16px;
	background: var(--selected-overlay-light);
	user-select: none;
`

const MenuFooterContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	flex-grow: 1;
	flex-shrink: 0;
	min-width: 0px;
	min-height: 0px;
	max-height: 100%;
`

const KeyboardCommandInnerContainer = styled.div`
	margin-right: 12px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
`

const KeyboardCommandGroup = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	> :not(:first-child) {
		margin-left: 4px;
	}
`
const KeyboardCommandLabel = styled.p`
	font-family: 'Inter', 'Helvetica Neue', sans-serif;
	font-size: 10px;
	font-weight: 400;
	line-height: 16px;
	word-break: break-word;
	letter-spacing: 0.01em;
	margin-left: 4px;
`

const IconContainer = styled.div`
	margin-right: 8px;
`

const ItemText = styled.span`
	font-family: 'Inter', 'Helvetica Neue', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	word-break: break-word;
`

const Section = styled.div`
	border-top: 1px dashed #ccc;
	&:first-child {
		border-top: 0;
	}
`

const SectionTitle = styled.div`
	text-align: left;
	padding: 10px 0 0 10px;
	font-size: 14px;
	font-weight: 500;
	color: #777;
`

const SelectedChipContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`

// Chip
const ChipContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 2px;
	padding-right: 8px;
	width: auto;
	height: 22px;
	border-radius: 6px;
	color: ${(props) =>
		props.primary
			? 'var(--selected-text-main)'
			: 'var(--selected-text-secondary)'};
	background-color: ${(props) =>
		props.transparent ? 'transparent' : 'rgb(229, 229, 229)'};
	font-size: 14px;
	font-weight: 500;
	white-space: nowrap;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	user-select: none;
`
const ChipFilterTypeContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-right: 8px;
	padding: 0 8px;
	width: auto;
	height: 100%;
	background-color: rgb(186, 181, 182);
	color: var(--selected-text-main);
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 1.2px;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
`

const ChipIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto auto auto 8px;
	cursor: pointer;
`
const ChipHelperText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px 8px;
	width: 16px;
	height: 16px;
	color: var(--selected-text-secondary);
	font-size: 16px;
	font-weight: 500;
`

const FilterType = ({ children }) => {
	return (
		<ChipContainer transparent={true}>
			{children}
			<ChipIconContainer>
				<ChevronRightIcon />
			</ChipIconContainer>
		</ChipContainer>
	)
}

const FilterOption = ({ filterOption, onRemove }) => {
	const { filterType, option } = filterOption
	return (
		<ChipContainer transparent={false} primary={true}>
			<ChipFilterTypeContainer>{filterType}</ChipFilterTypeContainer>
			{option}
			<ChipIconContainer onClick={onRemove}>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='16'
					height='16'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				>
					<line x1='18' y1='6' x2='6' y2='18'></line>
					<line x1='6' y1='6' x2='18' y2='18'></line>
				</svg>
			</ChipIconContainer>
		</ChipContainer>
	)
}

// Keyboard
const KeyboardCommandContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 4px;
	min-width: 16px;
	height: 16px;
	border-radius: 3px;
	background: var(--selected-overlay-dark);
	color: var(--selected-text-main);
`

const KeyboardCommandKey = styled.p`
	text-align: center;
	font-family: 'Inter', 'Helvetica Neue', sans-serif;
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
	word-break: break-word;
	letter-spacing: 0.01em;
`

const KeyboardCommand = (props) => {
	return (
		<KeyboardCommandContainer>
			<KeyboardCommandKey>{props.children}</KeyboardCommandKey>
		</KeyboardCommandContainer>
	)
}

export {
	SearchSectionContainer,
	SearchContainer,
	SearchbarContainer,
	SearchbarInnerContainer,
	SearchIconContainer,
	ComboBox,
	Input,
	MenuContainer,
	MenuInnerContainer,
	MenuListContainer,
	MenuHeaderText,
	MenuItemContainer,
	MenuFooter,
	MenuFooterContent,
	KeyboardCommandInnerContainer,
	KeyboardCommandGroup,
	KeyboardCommandLabel,
	IconContainer,
	ItemText,
	Section,
	SectionTitle,
	SelectedChipContainer,
	FilterType,
	FilterOption,
	ChipHelperText,
	KeyboardCommand,
}
