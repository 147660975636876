import { takeLatest, put, all, call } from 'redux-saga/effects'
import {
	fetchInitialThreadsStart,
	fetchInitialThreadsSuccess,
	fetchInitialThreadsFail,
} from './threads.slice'
import { handleFetchThreads } from './threads.helpers'

export function* fetchInitialThreads({ payload }) {
	try {
		const threads = yield handleFetchThreads(payload)
		yield put(fetchInitialThreadsSuccess(threads))
	} catch (error) {
		yield put(
			fetchInitialThreadsFail('There was an error getting threads: ' + error)
		)
	}
}

export function* onFetchInitialThreadsStart() {
	yield takeLatest(fetchInitialThreadsStart.type, fetchInitialThreads)
}

export default function* threadsSaga() {
	yield all([call(onFetchInitialThreadsStart)])
}
