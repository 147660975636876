import React from 'react'

// components
import {
	AuthorContainer,
	AvatarContainer,
	AvatarMask,
	Avatar,
	AuthorNameContainer,
	AuthorName,
	AuthorHandle,
	IconContainer,
} from './components'

// icons
import TwitterIcon from '../../../Assets/Icons/TwitterIcon'

const AuthorAvatar = (props) => {
	const { displayName, handle, avatarURL, twitterURL, threadTwitterURL } = props
	return (
		<AuthorContainer>
			<AvatarContainer>
				<AvatarMask>
					<Avatar src={avatarURL} />
				</AvatarMask>
			</AvatarContainer>

			<AuthorNameContainer>
				<AuthorName>{displayName}</AuthorName>
				<AuthorHandle>@{handle}</AuthorHandle>
			</AuthorNameContainer>

			<a href={threadTwitterURL} target='_blank' rel='noreferrer'>
				<IconContainer>
					<TwitterIcon />
				</IconContainer>
			</a>
		</AuthorContainer>
	)
}

export default AuthorAvatar
