import React from 'react'
import { useNavigate } from 'react-router-dom'

// components
import { TabsSection, Tab } from './components'

const DiscoverTabs = (props) => {
	const navigate = useNavigate()
	const { activePage } = props

	const handleTabItemClick = (value) => {
		navigate(value)
	}

	return (
		<TabsSection>
			<Tab
				active={activePage === 'content'}
				onClick={() => handleTabItemClick('/discover/content')}
			>
				Content
			</Tab>
			<Tab
				active={activePage === 'authors'}
				onClick={() => handleTabItemClick('/discover/authors')}
			>
				Authors
			</Tab>
		</TabsSection>
	)
}

export default DiscoverTabs
