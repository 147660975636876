function ChevronsRightIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<polyline points='13 17 18 12 13 7'></polyline>
			<polyline points='6 17 11 12 6 7'></polyline>
		</svg>
	)
}

export default ChevronsRightIcon
