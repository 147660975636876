import styled from 'styled-components'

const Page = styled.div`
	margin: 0px auto;
	display: flex;
	flex-direction: column;
	isolation: isolate;
`

export { Page }
