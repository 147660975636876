import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddle from 'redux-saga'

import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddle()

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: false,
		}).concat(sagaMiddleware),
})

sagaMiddleware.run(rootSaga)

export default store
