import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// utils
import { formatTweet } from '../../../Utils/text'

// components
import TweetHead from './tweetHead'
import TweetMedia from './tweetMedia'

// styled
const TweetContainer = styled.div`
	max-width: 600px;
`

const TweetCard = styled.div`
	border-radius: 4px;
	border-width: 1px;
	border-style: solid;
	border-color: var(--selected-border-color);
`

const TweetContentContainer = styled.div`
	padding: 16px;
`

const TweetBody = styled.div``

const TweetText = styled.span`
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3125;
	white-space: pre-line;
	font-family: 'Inter', 'Helvetica Neue', sans-serif;
`

const ThreadConnector = styled.div`
	width: 2px;
	height: 80px;
	background-color: var(--selected-border-color);
	position: relative;
	left: 48px;
`

const TweetInPeek = (props) => {
	const { isLast, authorObj, tweetObj } = props
	const [hasMedia, setHasMedia] = useState(false)
	const { tweet, photos } = tweetObj
	const { author, handle, avatarURL, twitterURL } = authorObj

	useEffect(() => {
		if (Array.isArray(photos) && photos.length > 0) {
			setHasMedia(true)
		}
	}, [])

	const formattedTweet = formatTweet(tweet)

	const configTweetHead = {
		author: author,
		handle: handle,
		avatarURL: avatarURL,
		twitterURL: twitterURL,
	}

	return (
		<div>
			<TweetContainer>
				<TweetCard>
					<TweetContentContainer>
						<TweetHead {...configTweetHead} />
						<TweetBody>
							{formattedTweet.split('  ').map((i, key) => {
								return <TweetText key={key}>{i}</TweetText>
							})}
							{hasMedia && <TweetMedia photos={photos} />}
						</TweetBody>
					</TweetContentContainer>
				</TweetCard>
			</TweetContainer>
			{!isLast && <ThreadConnector />}
		</div>
	)
}

export default TweetInPeek
