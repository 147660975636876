import React, { useState, useEffect } from 'react'

// data
import { authorsFilterTypes, authorsGroupedFilterOptions } from './filterItems'

// utils
import {
	addAuthorsFilterOption,
	removeFilterOption,
	removeLastFilterOption,
	parseURLValidAuthorsFilters,
	matchQueryParamsWithMetaFilters,
} from '../../Utils/search'

// components
import DiscoverTabs from '../../Components/DiscoverTabs'
import Search from '../../Components/Search'
import { Page } from '../components'

const DiscoverAuthors = () => {
	const [selectedFilterType, setSelectedFilterType] = useState(null)
	const [selectedFilterOption, setSelectedFilterOption] = useState(null)
	const [selectedFilterOptions, setSelectedFilterOptions] = useState([])

	// for search
	const handleFilterTypeChange = (value) => {
		setSelectedFilterType(value)
	}

	const handleFilterOptionChange = (value) => {
		setSelectedFilterOption(value)
	}

	const handleAddFilterOption = (option) => {
		const updatedOptions = addAuthorsFilterOption(selectedFilterOptions, option)
		// dispatch(setAuthorsFilters(updatedOptions))
		setSelectedFilterOptions(updatedOptions)
	}

	const handleRemoveFilterOption = (option) => {
		const updatedOptions = removeFilterOption(selectedFilterOptions, option)
		// dispatch(setAuthorsFilters(updatedOptions))
		setSelectedFilterOptions(updatedOptions)
	}

	const handleRemoveLastFilterOption = () => {
		const updatedOptions = removeLastFilterOption(selectedFilterOptions)
		// dispatch(setAuthorsFilters(updatedOptions))
		setSelectedFilterOptions(updatedOptions)
	}

	const configSearch = {
		topLevelFilters: authorsFilterTypes,
		groupedOptions: authorsGroupedFilterOptions,
		placeholderText: 'Search authors by...',
		selectedFilterType: selectedFilterType,
		onFilterTypeChange: handleFilterTypeChange,
		selectedFilterOption: selectedFilterOption,
		onFilterOptionChange: handleFilterOptionChange,
		selectedFilterOptions: selectedFilterOptions,
		onAddFilterOption: handleAddFilterOption,
		onRemoveFilterOption: handleRemoveFilterOption,
		onRemoveLastFilterOption: handleRemoveLastFilterOption,
	}

	return (
		<Page>
			<DiscoverTabs activePage='authors' />
			<Search {...configSearch} />
		</Page>
	)
}

export default DiscoverAuthors
