import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

// components & icons
import MenuIcon from '../../Assets/Icons/MenuIcon'
import XIconMd from '../../Assets/Icons/XIconMd'

import {
	NavbarContainer,
	Navbar,
	MobileMenuContainer,
	LogoContainer,
	TabsContainer,
	TabLabel,
	SignInBtnsContainer,
	SignInBtn,
	SignUpBtn,
} from './components'

const Header = (props) => {
	const { mobileMenuOpen, onOpenMobileMenu } = props
	const navigate = useNavigate()
	const location = useLocation()
	const { pathname } = location

	const handleTabItemClick = (value) => {
		navigate(value)
	}

	return (
		<NavbarContainer>
			<Navbar>
				<MobileMenuContainer onClick={onOpenMobileMenu}>
					{mobileMenuOpen ? <XIconMd /> : <MenuIcon />}
				</MobileMenuContainer>

				<LogoContainer>
					<Link to='/'>Density</Link>
				</LogoContainer>

				<TabsContainer>
					<TabLabel
						active={
							pathname === '/discover/authors' ||
							pathname === '/discover/content'
						}
						onClick={() => handleTabItemClick('/discover/authors')}
					>
						Discover
					</TabLabel>
					<TabLabel
						active={pathname === '/library'}
						onClick={() => handleTabItemClick('/library')}
					>
						Library
					</TabLabel>
				</TabsContainer>

				<SignInBtnsContainer>
					<Link to='/login'>
						<SignInBtn>
							<span>Log in</span>
						</SignInBtn>
					</Link>
					<Link to='/signup'>
						<SignUpBtn>
							<span>Sign up</span>
						</SignUpBtn>
					</Link>
				</SignInBtnsContainer>
			</Navbar>
		</NavbarContainer>
	)
}

export default Header
