const filterItems = [
	{
		value: 'crypto investor',
		filterType: 'Professions',
	},
	{
		value: 'solo-capitalist',
		filterType: 'Professions',
	},
	{
		value: 'podcaster',
		filterType: 'Professions',
	},
	{
		value: 'writer',
		filterType: 'Professions',
	},
	{
		value: 'entrepreneur',
		filterType: 'Professions',
	},
	{
		value: 'venture capitalist',
		filterType: 'Professions',
	},
	{
		value: 'blogger',
		filterType: 'Professions',
	},
	{
		value: 'angel investor',
		filterType: 'Professions',
	},
	{
		value: 'founder',
		filterType: 'Professions',
	},
	{
		value: 'product leader',
		filterType: 'Professions',
	},
	{
		value: 'product advisor',
		filterType: 'Professions',
	},
	{
		value: 'essayist',
		filterType: 'Professions',
	},
	{
		value: 'product ledaer',
		filterType: 'Professions',
	},
	{
		value: 'designer',
		filterType: 'Professions',
	},
	{
		value: 'Business Account',
		filterType: 'Professions',
	},
	{
		value: 'product manager',
		filterType: 'Professions',
	},
	{
		value: 'PE investor',
		filterType: 'Professions',
	},
	{
		value: 'Substacker',
		filterType: 'Professions',
	},
	{
		value: 'author',
		filterType: 'Professions',
	},
	{
		value: 'computer scientist',
		filterType: 'Professions',
	},
	{
		value: 'marketer',
		filterType: 'Professions',
	},
	{
		value: 'engineer',
		filterType: 'Professions',
	},
	{
		value: 'Pomp',
		filterType: 'Affiliations',
	},
	{
		value: 'Facebook',
		filterType: 'Affiliations',
	},
	{
		value: 'Y Combinator',
		filterType: 'Affiliations',
	},
	{
		value: 'OpenAI',
		filterType: 'Affiliations',
	},
	{
		value: 'a16z',
		filterType: 'Affiliations',
	},
	{
		value: 'Uber',
		filterType: 'Affiliations',
	},
	{
		value: 'Reforge',
		filterType: 'Affiliations',
	},
	{
		value: 'AngelList',
		filterType: 'Affiliations',
	},
	{
		value: 'My First Million',
		filterType: 'Affiliations',
	},
	{
		value: 'Twitch',
		filterType: 'Affiliations',
	},
	{
		value: 'Late Checkout',
		filterType: 'Affiliations',
	},
	{
		value: 'Tiktok',
		filterType: 'Affiliations',
	},
	{
		value: 'WeWork',
		filterType: 'Affiliations',
	},
	{
		value: 'Morning Brew',
		filterType: 'Affiliations',
	},
	{
		value: 'passion economy',
		filterType: 'Affiliations',
	},
	{
		value: 'Reddit',
		filterType: 'Affiliations',
	},
	{
		value: 'Seven Seven Six',
		filterType: 'Affiliations',
	},
	{
		value: 'Initialized Capital',
		filterType: 'Affiliations',
	},
	{
		value: 'Amazon',
		filterType: 'Affiliations',
	},
	{
		value: 'Hulu',
		filterType: 'Affiliations',
	},
	{
		value: 'Flipboard',
		filterType: 'Affiliations',
	},
	{
		value: 'Oculus',
		filterType: 'Affiliations',
	},
	{
		value: 'Visualize Value',
		filterType: 'Affiliations',
	},
	{
		value: 'GOAT Capital',
		filterType: 'Affiliations',
	},
	{
		value: 'Twitter',
		filterType: 'Affiliations',
	},
	{
		value: 'Stripe',
		filterType: 'Affiliations',
	},
	{
		value: 'Altamont Capital Partners',
		filterType: 'Affiliations',
	},
	{
		value: 'Gumroad',
		filterType: 'Affiliations',
	},
	{
		value: 'Chapter One',
		filterType: 'Affiliations',
	},
	{
		value: 'Tinder',
		filterType: 'Affiliations',
	},
	{
		value: 'Demand Curve',
		filterType: 'Affiliations',
	},
	{
		value: 'Roam Research',
		filterType: 'Affiliations',
	},
	{
		value: 'Coinbase',
		filterType: 'Affiliations',
	},
	{
		value: 'Palantir',
		filterType: 'Affiliations',
	},
	{
		value: 'Lightspeed Venture Partners',
		filterType: 'Affiliations',
	},
	{
		value: 'Product Hunt',
		filterType: 'Affiliations',
	},
	{
		value: 'Weekend Fund',
		filterType: 'Affiliations',
	},
	{
		value: 'Benchmark Capital',
		filterType: 'Affiliations',
	},
	{
		value: 'crypto',
		filterType: 'TweetsAbout',
	},
	{
		value: 'startup',
		filterType: 'TweetsAbout',
	},
	{
		value: 'marketplaces',
		filterType: 'TweetsAbout',
	},
	{
		value: 'consumer products',
		filterType: 'TweetsAbout',
	},
	{
		value: 'growth',
		filterType: 'TweetsAbout',
	},
	{
		value: 'life wisdom',
		filterType: 'TweetsAbout',
	},
	{
		value: 'tech',
		filterType: 'TweetsAbout',
	},
	{
		value: 'philosophy',
		filterType: 'TweetsAbout',
	},
	{
		value: 'business',
		filterType: 'TweetsAbout',
	},
	{
		value: 'community building',
		filterType: 'TweetsAbout',
	},
	{
		value: 'venture investing',
		filterType: 'TweetsAbout',
	},
	{
		value: 'community-based products',
		filterType: 'TweetsAbout',
	},
	{
		value: 'startups',
		filterType: 'TweetsAbout',
	},
	{
		value: 'creator economy',
		filterType: 'TweetsAbout',
	},
	{
		value: 'venture capital',
		filterType: 'TweetsAbout',
	},
	{
		value: 'gaming',
		filterType: 'TweetsAbout',
	},
	{
		value: 'product',
		filterType: 'TweetsAbout',
	},
	{
		value: 'memes',
		filterType: 'TweetsAbout',
	},
	{
		value: 'mindfulness',
		filterType: 'TweetsAbout',
	},
	{
		value: 'product management',
		filterType: 'TweetsAbout',
	},
	{
		value: 'finance',
		filterType: 'TweetsAbout',
	},
	{
		value: 'marketing',
		filterType: 'TweetsAbout',
	},
	{
		value: 'mental models',
		filterType: 'TweetsAbout',
	},
	{
		value: '#Startups',
		filterType: 'TweetsAbout',
	},
	{
		value: 'growth marketing',
		filterType: 'TweetsAbout',
	},
	{
		value: 'writing',
		filterType: 'TweetsAbout',
	},
	{
		value: 'knowledge management',
		filterType: 'TweetsAbout',
	},
	{
		value: 'productivity',
		filterType: 'TweetsAbout',
	},
	{
		value: '#Creator Economy',
		filterType: 'allTags',
	},
	{
		value: '#Break Into VC',
		filterType: 'allTags',
	},
	{
		value: '#Passion Economy',
		filterType: 'allTags',
	},
	{
		value: '#1000 True Fans',
		filterType: 'allTags',
	},
	{
		value: '#Marketplace',
		filterType: 'allTags',
	},
	{
		value: '#Startups',
		filterType: 'allTags',
	},
	{
		value: '#Hiring',
		filterType: 'allTags',
	},
	{
		value: '#Discussion',
		filterType: 'allTags',
	},
	{
		value: '#Prediction',
		filterType: 'allTags',
	},
	{
		value: '#Company Culture',
		filterType: 'allTags',
	},
	{
		value: '#Venture Investing',
		filterType: 'allTags',
	},
	{
		value: '#Goal Setting',
		filterType: 'allTags',
	},
	{
		value: '#Life Advice',
		filterType: 'allTags',
	},
	{
		value: '#How to be Successful',
		filterType: 'allTags',
	},
	{
		value: '#Learning',
		filterType: 'allTags',
	},
	{
		value: '#Life Wisdom',
		filterType: 'allTags',
	},
	{
		value: '#Policy Making',
		filterType: 'allTags',
	},
	{
		value: '#Observation',
		filterType: 'allTags',
	},
	{
		value: '#Management',
		filterType: 'allTags',
	},
	{
		value: '#Recommendations',
		filterType: 'allTags',
	},
	{
		value: '#Career Advice',
		filterType: 'allTags',
	},
	{
		value: '#Entrepreneurship',
		filterType: 'allTags',
	},
	{
		value: '#Silicon Valley',
		filterType: 'allTags',
	},
	{
		value: '#Essay',
		filterType: 'allTags',
	},
	{
		value: '#CrowdWisdom',
		filterType: 'allTags',
	},
	{
		value: '#Trends',
		filterType: 'allTags',
	},
	{
		value: '#Politics',
		filterType: 'allTags',
	},
	{
		value: '#Religion',
		filterType: 'allTags',
	},
	{
		value: '#Investor Update',
		filterType: 'allTags',
	},
	{
		value: '#How to Get Rich',
		filterType: 'allTags',
	},
	{
		value: '#Product Management',
		filterType: 'allTags',
	},
	{
		value: '#Frameworks',
		filterType: 'allTags',
	},
	{
		value: '#Compounding Effect',
		filterType: 'allTags',
	},
	{
		value: '#Leadership',
		filterType: 'allTags',
	},
	{
		value: '#SocialCause',
		filterType: 'allTags',
	},
	{
		value: '#MentalModels',
		filterType: 'allTags',
	},
	{
		value: '#Commentary',
		filterType: 'allTags',
	},
	{
		value: '#Email Writing',
		filterType: 'allTags',
	},
	{
		value: 'Pomp',
		filterType: 'Authors',
	},
	{
		value: 'Sam Altman',
		filterType: 'Authors',
	},
	{
		value: 'Andrew Chen',
		filterType: 'Authors',
	},
	{
		value: 'Naval',
		filterType: 'Authors',
	},
	{
		value: 'Shaan Puri',
		filterType: 'Authors',
	},
	{
		value: 'GREG ISENBERG',
		filterType: 'Authors',
	},
	{
		value: 'Austin Rief',
		filterType: 'Authors',
	},
	{
		value: 'Li Jin',
		filterType: 'Authors',
	},
	{
		value: 'Alexis Ohanian',
		filterType: 'Authors',
	},
	{
		value: 'Matthew Ball',
		filterType: 'Authors',
	},
	{
		value: 'Eugene Wei',
		filterType: 'Authors',
	},
	{
		value: 'Jack Butcher',
		filterType: 'Authors',
	},
	{
		value: 'Justin Kan',
		filterType: 'Authors',
	},
	{
		value: 'Visualize Value',
		filterType: 'Authors',
	},
	{
		value: 'Shreyas Doshi',
		filterType: 'Authors',
	},
	{
		value: 'Sahil Bloom',
		filterType: 'Authors',
	},
	{
		value: 'Paul Graham',
		filterType: 'Authors',
	},
	{
		value: 'Sahil',
		filterType: 'Authors',
	},
	{
		value: 'Jeff Morris Jr.',
		filterType: 'Authors',
	},
	{
		value: 'Julian Shapiro',
		filterType: 'Authors',
	},
	{
		value: 'Conor White-Sullivan',
		filterType: 'Authors',
	},
	{
		value: 'Garry Tan',
		filterType: 'Authors',
	},
	{
		value: 'Tim Ferriss',
		filterType: 'Authors',
	},
	{
		value: 'Alex Taussig',
		filterType: 'Authors',
	},
	{
		value: 'Ryan Hoover',
		filterType: 'Authors',
	},
	{
		value: 'Bill Gurley',
		filterType: 'Authors',
	},
]

const authorsGroupedFilterOptions = [
	{
		filterType: 'Authors',
		options: [
			{ value: 'Pomp' },
			{ value: 'Sam Altman' },
			{ value: 'Andrew Chen' },
			{ value: 'Naval' },
			{ value: 'Shaan Puri' },
			{ value: 'GREG ISENBERG' },
			{ value: 'Austin Rief' },
			{ value: 'Li Jin' },
			{ value: 'Alexis Ohanian' },
			{ value: 'Matthew Ball' },
			{ value: 'Eugene Wei' },
			{ value: 'Jack Butcher' },
			{ value: 'Justin Kan' },
			{ value: 'Visualize Value' },
			{ value: 'Shreyas Doshi' },
			{ value: 'Sahil Bloom' },
			{ value: 'Paul Graham' },
			{ value: 'Sahil' },
			{ value: 'Jeff Morris Jr.' },
			{ value: 'Julian Shapiro' },
			{ value: 'Conor White-Sullivan' },
			{ value: 'Garry Tan' },
			{ value: 'Tim Ferriss' },
			{ value: 'Alex Taussig' },
			{ value: 'Ryan Hoover' },
			{ value: 'Bill Gurley' },
		],
	},
	{
		filterType: 'Professions',
		options: [
			{ value: 'crypto investor' },
			{ value: 'solo-capitalist' },
			{ value: 'podcaster' },
			{ value: 'writer' },
			{ value: 'entrepreneur' },
			{ value: 'venture capitalist' },
			{ value: 'blogger' },
			{ value: 'angel investor' },
			{ value: 'founder' },
			{ value: 'product leader' },
			{ value: 'product advisor' },
			{ value: 'essayist' },
			{ value: 'product ledaer' },
			{ value: 'designer' },
			{ value: 'Business Account' },
			{ value: 'product manager' },
			{ value: 'PE investor' },
			{ value: 'Substacker' },
			{ value: 'author' },
			{ value: 'computer scientist' },
			{ value: 'marketer' },
			{ value: 'engineer' },
		],
	},
	{
		filterType: 'Affiliations',
		options: [
			{ value: 'Pomp' },
			{ value: 'Facebook' },
			{ value: 'Y Combinator' },
			{ value: 'OpenAI' },
			{ value: 'a16z' },
			{ value: 'Uber' },
			{ value: 'Reforge' },
			{ value: 'AngelList' },
			{ value: 'My First Million' },
			{ value: 'Twitch' },
			{ value: 'Late Checkout' },
			{ value: 'Tiktok' },
			{ value: 'WeWork' },
			{ value: 'Morning Brew' },
			{ value: 'passion economy' },
			{ value: 'Reddit' },
			{ value: 'Seven Seven Six' },
			{ value: 'Initialized Capital' },
			{ value: 'Amazon' },
			{ value: 'Hulu' },
			{ value: 'Flipboard' },
			{ value: 'Oculus' },
			{ value: 'Visualize Value' },
			{ value: 'GOAT Capital' },
			{ value: 'Twitter' },
			{ value: 'Stripe' },
			{ value: 'Altamont Capital Partners' },
			{ value: 'Gumroad' },
			{ value: 'Chapter One' },
			{ value: 'Tinder' },
			{ value: 'Demand Curve' },
			{ value: 'Roam Research' },
			{ value: 'Coinbase' },
			{ value: 'Palantir' },
			{ value: 'Lightspeed Venture Partners' },
			{ value: 'Product Hunt' },
			{ value: 'Weekend Fund' },
			{ value: 'Benchmark Capital' },
		],
	},
	{
		filterType: 'TweetsAbout',
		options: [
			{ value: 'Crypto' },
			{ value: 'Marketplaces' },
			{ value: 'Consumer Products' },
			{ value: 'Growth' },
			{ value: 'Life Wisdom' },
			{ value: 'Tech' },
			{ value: 'Philosophy' },
			{ value: 'Business' },
			{ value: 'Community Building' },
			{ value: 'Venture Investing' },
			{ value: 'Community-based products' },
			{ value: 'Startups' },
			{ value: 'Creator Economy' },
			{ value: 'Venture Capital' },
			{ value: 'Gaming' },
			{ value: 'Product' },
			{ value: 'Memes' },
			{ value: 'Mindfulness' },
			{ value: 'Product Management' },
			{ value: 'Finance' },
			{ value: 'Marketing' },
			{ value: 'Mental Models' },
			{ value: 'Startups' },
			{ value: 'Growth Marketing' },
			{ value: 'Writing' },
			{ value: 'Knowledge Management' },
			{ value: 'Productivity' },
		],
	},
	{
		filterType: 'Tags',
		options: [
			{ value: 'Creator Economy' },
			{ value: 'Break Into VC' },
			{ value: 'Passion Economy' },
			{ value: '1000 True Fans' },
			{ value: 'Marketplace' },
			{ value: 'Startups' },
			{ value: 'Hiring' },
			{ value: 'Discussion' },
			{ value: 'Prediction' },
			{ value: 'Company Culture' },
			{ value: 'Venture Investing' },
			{ value: 'Goal Setting' },
			{ value: 'Life Advice' },
			{ value: '#How to be Successful' },
			{ value: '#Learning' },
			{ value: '#Life Wisdom' },
			{ value: '#Policy Making' },
			{ value: '#Observation' },
			{ value: '#Management' },
			{ value: '#Recommendations' },
			{ value: '#Career Advice' },
			{ value: '#Entrepreneurship' },
			{ value: '#Silicon Valley' },
			{ value: '#Essay' },
			{ value: '#CrowdWisdom' },
			{ value: '#Trends' },
			{ value: '#Politics' },
			{ value: '#Religion' },
			{ value: '#Investor Update' },
			{ value: '#How to Get Rich' },
			{ value: '#Product Management' },
			{ value: '#Frameworks' },
			{ value: '#Compounding Effect' },
			{ value: '#Leadership' },
			{ value: '#SocialCause' },
			{ value: '#MentalModels' },
			{ value: '#Commentary' },
			{ value: '#Email Writing' },
		],
	},
]

const authorsFilterTypes = [
	{ value: 'Authors', filterType: 'TopLevel', icon: 'Authors' },
	{ value: 'Professions', filterType: 'TopLevel', icon: 'Professions' },
	{ value: 'Affiliations', filterType: 'TopLevel', icon: 'Affiliations' },
	{ value: 'TweetsAbout', filterType: 'TopLevel', icon: 'TweetsAbout' },
]

export { filterItems, authorsFilterTypes, authorsGroupedFilterOptions }
