import { useState, useEffect } from 'react'

const useWindowSize = () => {
	// Initialize state with undefined width/height so server and client renders match
	const isSSR = typeof window === 'undefined'
	const [windowSize, setWindowSize] = useState({
		width: isSSR ? undefined : window.innerWidth,
		height: isSSR ? undefined : window.innerHeight,
	})

	function handleResize() {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		})
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return windowSize
}

export default useWindowSize
