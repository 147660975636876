import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// styled
const NavContainer = styled.nav`
	width: 200px;
	margin: 0 auto;
	font-size: 32px;
`

const NavOptionsContainer = styled.ul`
	list-style: none;

	& > li {
		margin-top: 20px;
	}
`

const Homepage = () => {
	return (
		<NavContainer>
			<NavOptionsContainer>
				<li>
					<Link to='/'>Home</Link>
				</li>
				<li>
					<Link to='/discover/content'>Discover</Link>
				</li>
				<li>
					<Link to='/library'>Library</Link>
				</li>
			</NavOptionsContainer>
		</NavContainer>
	)
}

export default Homepage
