import styled from 'styled-components'

const GalleryViewContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden auto;
`

const GalleryContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
`

const GalleryCenteredContainer = styled.div`
	padding: 24px;
	width: 100%;
`

const GridItem = styled.div`
	width: 280px;
`

const GridContainer = styled.div`
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
`

const EndOfPageIndicatorContainer = styled.div`
	padding-bottom: 24px;
`

const TextContainer = styled.p`
	font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	word-break: break-word;
	letter-spacing: -0.00622354em;
`

export {
	GalleryViewContainer,
	GalleryContainer,
	GalleryCenteredContainer,
	GridItem,
	GridContainer,
	EndOfPageIndicatorContainer,
	TextContainer,
}
