import React, { useState } from 'react'
import { useCombobox } from 'downshift'
import { matchSorter } from 'match-sorter'

// helpers
import { flattenGroupedOptions } from './helpers'

// components
import {
	SearchSectionContainer,
	SearchContainer,
	SearchbarContainer,
	SearchbarInnerContainer,
	SearchIconContainer,
	ComboBox,
	Input,
	MenuContainer,
	MenuInnerContainer,
	MenuListContainer,
	MenuHeaderText,
	MenuItemContainer,
	MenuFooter,
	MenuFooterContent,
	KeyboardCommandInnerContainer,
	KeyboardCommandGroup,
	KeyboardCommandLabel,
	IconContainer,
	ItemText,
	Section,
	SectionTitle,
	SelectedChipContainer,
	FilterType,
	FilterOption,
	ChipHelperText,
	KeyboardCommand,
} from './components'
import SearchIcon from '../../Assets/Icons/SearchIcon'
import UserIcon from '../../Assets/Icons/UserIcon'
import UsersIcon from '../../Assets/Icons/UsersIcon'
import BriefcaseIcon from '../../Assets/Icons/BriefcaseIcon'
import HashtagIcon from '../../Assets/Icons/HashtagIcon'
import PaperClipIcon from '../../Assets/Icons/PaperClipIcon'

function optionFilterFunc(items, inputValue) {
	return matchSorter(items, inputValue, { keys: ['value'] })
}

const renderFilterOption = (option) => {
	const ICONS = {
		Authors: <UserIcon />,
		Professions: <BriefcaseIcon />,
		Affiliations: <UsersIcon />,
		TweetsAbout: <HashtagIcon />,
		Topics: <HashtagIcon />,
		Types: <PaperClipIcon />,
	}

	return (
		<>
			<IconContainer>{ICONS[option.icon]}</IconContainer>
			<ItemText>{option.value}</ItemText>
		</>
	)
}

const generateMenu = (menuProps) => {
	const {
		inputItems,
		getItemProps,
		highlightedIndex,
		inputValue,
		groupedFilterOptions,
		selectedFilterType,
	} = menuProps

	const filterOptionsList = inputItems.map((item, index) => {
		return (
			<MenuItemContainer
				{...getItemProps({
					key: `${item}${index}`,
					item,
					index,
					style: {
						backgroundColor: highlightedIndex === index && '#f4f4f4',
					},
				})}
			>
				{renderFilterOption(item)}
			</MenuItemContainer>
		)
	})

	const groupedFilterOptionsList = groupedFilterOptions.reduce(
		(results, section, sectionIndex) => {
			results.sections.push(
				<Section key={sectionIndex}>
					<SectionTitle>{section.filterType}</SectionTitle>
					{section.options.map((option, optionIndex) => {
						const resultIndex = results.itemIndex++

						const item = {
							value: option.value,
							filterType: section.filterType,
							icon: section.filterType,
						}

						return (
							<MenuItemContainer
								key={optionIndex}
								{...getItemProps({
									item: option,
									index: resultIndex,
									style: {
										backgroundColor:
											highlightedIndex === resultIndex && '#f4f4f4',
									},
								})}
							>
								{renderFilterOption(item)}
							</MenuItemContainer>
						)
					})}
				</Section>
			)

			return results
		},
		{
			sections: [],
			itemIndex: 0,
		}
	).sections

	let renderList

	if (!inputValue) {
		renderList = filterOptionsList
	} else if (!selectedFilterType) {
		renderList = groupedFilterOptionsList
	} else {
		renderList = filterOptionsList
	}

	return <MenuListContainer>{renderList}</MenuListContainer>
}

function stateReducer(state, actionAndChanges) {
	const { type, changes } = actionAndChanges

	switch (type) {
		case useCombobox.stateChangeTypes.InputBlur:
		case useCombobox.stateChangeTypes.InputKeyDownEscape:
			return {
				...changes,
				isOpen: false,
				inputValue: '',
				selectedItem: null,
			}
		case useCombobox.stateChangeTypes.InputKeyDownEnter:
		case useCombobox.stateChangeTypes.ItemClick:
			return {
				...changes,
				inputValue: '',
				isOpen: true,
			}
		default:
			return changes
	}
}

const Search = (props) => {
	const {
		topLevelFilters,
		groupedOptions,
		placeholderText,
		selectedFilterType,
		onFilterTypeChange,
		selectedFilterOption,
		onFilterOptionChange,
		selectedFilterOptions,
		onAddFilterOption,
		onRemoveFilterOption,
		onRemoveLastFilterOption,
	} = props
	const [inputItems, setInputItems] = useState(topLevelFilters)
	const [groupedFilterOptions, setGroupedFilterOptions] =
		useState(groupedOptions)

	const itemToString = (item) => (item ? item.value : '')

	const {
		isOpen,
		getInputProps,
		getComboboxProps,
		getMenuProps,
		getItemProps,
		highlightedIndex,
		inputValue,
		openMenu,
		closeMenu,
	} = useCombobox({
		items: inputItems,
		itemToString,
		stateReducer,
		onStateChange: (changes) => handleStateChange(changes),
		onInputValueChange: ({ inputValue }) => {
			handleInputValueChange(inputValue)
		},
		onSelectedItemChange: ({ selectedItem }) => {
			handleSelectedItemChange(selectedItem)
		},
	})

	const getSuggestions = (data, inputValue) => {
		return data
			.map((section) => {
				const filteredOptions = optionFilterFunc(
					section.options,
					inputValue || ''
				)

				return {
					filterType: section.filterType,
					options: filteredOptions,
				}
			})
			.filter((section) => section.options.length > 0)
	}

	const filterOptionsByType = (data, filterType) => {
		return data.filter((section) => section.filterType === filterType)
	}

	const omitSelectedOptions = (data, omitOptions) => {
		const remainingOptions = data
			.map((section) => {
				return {
					filterType: section.filterType,
					options: section.options.filter(
						(c) => !omitOptions.some((s) => s && s.option === c.value)
					),
				}
			})
			.filter((section) => section.options.length > 0)

		return remainingOptions
	}

	const handleStateChange = (changes) => {
		if (changes.hasOwnProperty('isOpen')) {
			// reset inputItems && filterTypeSelected when menu is closed
			if (!changes.selectedItem) {
				if (!changes.isOpen) {
					setInputItems(topLevelFilters)
					onFilterTypeChange(null)
					onFilterOptionChange(null)
				}
			}
		}
	}

	const handleInputValueChange = (inputValue) => {
		const remainingOptions = omitSelectedOptions(
			groupedOptions,
			selectedFilterOptions
		)

		const suggestions = getSuggestions(remainingOptions, inputValue)

		const remainingOptionsByType = filterOptionsByType(
			remainingOptions,
			selectedFilterType
		)

		const suggestionsByType = getSuggestions(remainingOptionsByType, inputValue)

		if (!selectedFilterType) {
			if (!inputValue) {
				setInputItems(topLevelFilters)
			} else {
				// used for Downshift internally
				setInputItems(flattenGroupedOptions(suggestions))

				// used for rendering menu
				setGroupedFilterOptions(suggestions)
			}
		} else {
			if (!inputValue) {
				setInputItems(flattenGroupedOptions(remainingOptionsByType))
			} else {
				// used for Downshift internally
				setInputItems(flattenGroupedOptions(suggestionsByType))

				// used for rendering menu; not necessary but for safety
				setGroupedFilterOptions(suggestionsByType)
			}
		}
	}

	const handleSelectedItemChange = (selectedItem) => {
		if (!selectedItem) {
			return
		}

		if (selectedItem.filterType === 'TopLevel') {
			// if a filter type is selected, change menu list items
			onFilterTypeChange(selectedItem.value)

			const remainingOptions = omitSelectedOptions(
				groupedOptions,
				selectedFilterOptions
			)

			const remainingOptionsByType = filterOptionsByType(
				remainingOptions,
				selectedItem.value
			)

			setInputItems(flattenGroupedOptions(remainingOptionsByType))
		} else {
			// if a filter option is selected, add it to selectedFilterOptions
			onFilterTypeChange(selectedItem.filterType)
			onFilterOptionChange({
				filterType: selectedItem.filterType,
				option: selectedItem.value,
			})
			onAddFilterOption({
				filterType: selectedItem.filterType,
				option: selectedItem.value,
			})

			closeMenu()
		}
	}

	const handleInputKeyDown = ({ event }) => {
		if (event.key === 'Backspace' && !event.target.value) {
			if (selectedFilterType) {
				// reset filter type
				onFilterTypeChange(null)
				// rest inputItems
				setInputItems(topLevelFilters)
			} else {
				// remove the last input
				onRemoveLastFilterOption()
			}
		}
	}

	const menuProps = {
		inputItems,
		getItemProps,
		highlightedIndex,
		inputValue,
		groupedFilterOptions,
		selectedFilterType,
	}

	let renderMenu = generateMenu(menuProps)

	return (
		<SearchSectionContainer>
			<SearchContainer>
				<SearchbarContainer>
					<SearchbarInnerContainer>
						<SearchIconContainer>
							<SearchIcon />
						</SearchIconContainer>

						{Array.isArray(selectedFilterOptions) &&
							selectedFilterOptions.length > 0 &&
							selectedFilterOptions.map((option, index) => {
								return (
									<SelectedChipContainer key={`selected-option-${index}`}>
										<FilterOption
											filterOption={option}
											onRemove={() => onRemoveFilterOption(option)}
										/>
										<ChipHelperText>+</ChipHelperText>
									</SelectedChipContainer>
								)
							})}

						{selectedFilterType && (
							<FilterType>{selectedFilterType}</FilterType>
						)}
						<ComboBox {...getComboboxProps()}>
							<Input
								placeholder={placeholderText}
								{...getInputProps({
									onFocus: openMenu,
									onClick: openMenu,
									onKeyDown: (event) => handleInputKeyDown({ event }),
								})}
							/>
						</ComboBox>
					</SearchbarInnerContainer>
				</SearchbarContainer>

				<MenuContainer {...getMenuProps()}>
					{isOpen && (
						<MenuInnerContainer>
							<MenuHeaderText>SUGGESTED FILTERS</MenuHeaderText>
							{renderMenu}
							<MenuFooter>
								<MenuFooterContent>
									<KeyboardCommandInnerContainer>
										<KeyboardCommandGroup>
											<KeyboardCommand>↑</KeyboardCommand>
											<KeyboardCommand>↓</KeyboardCommand>
										</KeyboardCommandGroup>
										<KeyboardCommandLabel>Move</KeyboardCommandLabel>
									</KeyboardCommandInnerContainer>

									<KeyboardCommand>Enter</KeyboardCommand>
									<KeyboardCommandLabel>Select</KeyboardCommandLabel>
								</MenuFooterContent>
							</MenuFooter>
						</MenuInnerContainer>
					)}
				</MenuContainer>
			</SearchContainer>
		</SearchSectionContainer>
	)
}

export default Search
