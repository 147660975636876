function HashtagIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<line x1='4' y1='9' x2='20' y2='9'></line>
			<line x1='4' y1='15' x2='20' y2='15'></line>
			<line x1='10' y1='3' x2='8' y2='21'></line>
			<line x1='16' y1='3' x2='14' y2='21'></line>
		</svg>
	)
}

export default HashtagIcon
