export const addTweetsFilterOption = (optionsArray, option) => {
	let updatedArray = []

	// only one Types option can be selected
	const typeSelected = !!optionsArray.find((el) => el.filterType === 'Types')

	if (option.filterType === 'Types' && typeSelected) {
		// find where the Types option is and replace it
		const indexToUpdate = optionsArray.findIndex(
			(el) => el.filterType === 'Types'
		)

		let arrayToUpdate = [...optionsArray]

		arrayToUpdate[indexToUpdate] = option

		updatedArray = arrayToUpdate
	} else {
		// otherwise, just append to the list
		updatedArray = [...optionsArray, option]
	}

	return updatedArray
}

export const addAuthorsFilterOption = (optionsArray, option) => {
	let updatedArray = []

	//if a specific Author is selected, rewrite the options array
	if (option.filterType === 'Authors') {
		updatedArray = [option]
	} else {
		// when an Author is already selected, other filters won't be added
		const authorSelected = !!optionsArray.find(
			(el) => el.filterType === 'Authors'
		)

		if (authorSelected) {
			// find where the Author option is and replace it
			const indexToUpdate = optionsArray.findIndex(
				(el) => el.filterType === 'Authors'
			)

			const currentAuthor = optionsArray[indexToUpdate]

			updatedArray = [currentAuthor]
		} else {
			// otherwise, just append to the list
			updatedArray = [...optionsArray, option]
		}
	}

	return updatedArray
}

export const removeFilterOption = (optionsArray, option) => {
	let updatedArray = []

	updatedArray = optionsArray.filter((c) => c !== option)

	return updatedArray
}

export const removeLastFilterOption = (optionsArray) => {
	let updatedArray = []

	if (optionsArray.length) {
		updatedArray = optionsArray.slice(0, optionsArray.length - 1)
	} else {
		updatedArray = []
	}

	return updatedArray
}

export const parseURLValidTweetsFilters = (searchParams) => {
	let filters = []
	const authors = 'Authors'
	const topics = 'Topics'
	const types = 'Types'

	// key-value pairs with same key will be double-counted
	let keys = new Set(searchParams.keys())

	for (let key of keys) {
		if (key === authors || key === topics || key === types) {
			for (let value of searchParams.getAll(key)) {
				const filterObj = { filterType: key, option: value }
				filters.push(filterObj)
			}
		}
	}

	return filters
}

export const parseURLValidAuthorsFilters = (searchParams) => {
	let filters = []
	const authors = 'Authors'
	const professions = 'Professions'
	const affiliations = 'Affiliations'
	const tweetsAbout = 'TweetsAbout'

	if (searchParams.has(authors)) {
		for (const value of searchParams.get(authors)) {
			const filterObj = { filterType: authors, option: value }
			filters = [filterObj]
		}
	}

	if (searchParams.has(professions)) {
		for (const value of searchParams.getAll(professions)) {
			const filterObj = { filterType: professions, option: value }
			filters.push(filterObj)
		}
	}

	if (searchParams.has(affiliations)) {
		for (const value of searchParams.getAll(affiliations)) {
			const filterObj = { filterType: affiliations, option: value }
			filters.push(filterObj)
		}
	}

	if (searchParams.has(tweetsAbout)) {
		for (const value of searchParams.getAll(tweetsAbout)) {
			const filterObj = { filterType: tweetsAbout, option: value }
			filters.push(filterObj)
		}
	}

	return filters
}

export const matchQueryParamsWithMetaFilters = (
	keyCorrectFilters,
	metaFilters
) => {
	let validFilters = []

	for (const filter in keyCorrectFilters) {
		const { filterType, option } = keyCorrectFilters[filter]

		const metaFilterGroup = metaFilters.find((x) => x.filterType === filterType)

		const availableFilterOptions = metaFilterGroup.options

		if (availableFilterOptions.find((x) => x.value === option)) {
			validFilters.push(keyCorrectFilters[filter])
		}
	}

	return validFilters
}
