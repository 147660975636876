import React from 'react'
import styled from 'styled-components'

// components
import TweetsGallery from './TweetsGallery'

// styled
const SectionContainer = styled.section`
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`

const TweetsResults = (props) => {
	const {
		handle,
		threads,
		initialThreadsStatus,
		initialThreadsError,
		filterOptions,
		onToggleModalEvt,
	} = props
	const { data, queryDoc, isLastPage } = threads

	const configThreads = {
		threads: data,
		isLastPage: isLastPage,
		onToggleModalEvt: onToggleModalEvt,
	}

	let renderThreads

	if (initialThreadsStatus === 'loading') {
		renderThreads = <h1>Loading threads...</h1>
	} else if (initialThreadsStatus === 'succeeded') {
		renderThreads = <TweetsGallery {...configThreads} />
	} else if (initialThreadsStatus === 'failed') {
		renderThreads = <div>error: {initialThreadsError}</div>
	}

	return <SectionContainer>{renderThreads}</SectionContainer>
}

export default TweetsResults
