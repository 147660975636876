import React from 'react'
import styled from 'styled-components'

const Parent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	background-color: #ebf1ff;
	color: #5384f4;
	border-radius: 3px;
	padding: 4px;
	margin: 0 4px 4px 0;
	width: fit-content;
	transition: all ease 50ms;

	&:hover {
		color: #fff;
		background-color: #5384f4;
	}
`

const TagLabel = styled.div`
	font-size: 12px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Inter', 'Helvetica Neue', sans-serif;
	padding-right: 4px;
`

const Tag = (props) => {
	const { onClick } = props

	return (
		<Parent onClick={onClick}>
			<TagLabel>{props.children}</TagLabel>
		</Parent>
	)
}

export default Tag
